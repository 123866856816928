import React, { PropsWithChildren } from "react";

export function RequiresAuth(props: PropsWithChildren<{}>) {
    const jwt = document.cookie.split(';').find(c => c.startsWith('jwt='))?.split('=')?.[1];
    
    if (jwt) {
        return <>{props.children}</>;
    } else {
        window.location.href = process.env.REACT_APP_AUTH_LOGIN_URL!;
        return null;
    }
}