import { Alert } from 'react-bootstrap';
import { Redirect, RouteProps } from 'react-router-dom';

export function AuthResponse(props: RouteProps) {
    const idToken = extractHashComponents(props.location?.hash).get('id_token');

    if (idToken !== null) {
        document.cookie = `jwt=${idToken}`;
        return <Redirect to="/" />
    } else {
        return <Alert variant="danger">No Token provided in Authentication Response</Alert>
    }
}

function extractHashComponents(hash: string | undefined): URLSearchParams {
    if (hash === undefined) {
        return new URLSearchParams();
    }
    
    if (hash[0] === '#') {
        hash = hash.substring(1);
    }

    return new URLSearchParams(hash);
}