import React, { useEffect, useRef, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { createLocalTracks } from "twilio-video";
import { connect, RemoteParticipant, RemoteTrack } from 'twilio-video';
import { UnauthenticatedError } from "../errors/unauthenticated-error";

export function Room(props: {}) {
  const [state, setState] = useState<{ track: RemoteTrack, remoteParticipant: RemoteParticipant, room: any } | { status: 'waiting-for-joiner', room: any } | { status: 'unauthenticated' } | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      if (state !== undefined) {
        return;
      }

      try {
        const room = await getRoom();
        const localTracks = await createLocalTracks({ audio: true, video: { width: 640 } });
        const connectedRoom = await connect(room.token, { name: room.name, tracks: localTracks });
        connectedRoom.on('trackStarted', (track, remoteParticipant) => {
          const videoplayer = ref.current;
          videoplayer?.appendChild((track as any).attach());

          setState({ track, remoteParticipant, room })
        });
        setState({ status: 'waiting-for-joiner', room });
      } catch (err) {
        if (err instanceof UnauthenticatedError) {
          setState({ status: 'unauthenticated' });
        }
      }
    })();
  });

  if (state === undefined) {
    return <>
      <Spinner animation='border' />
      <p>Loading...</p>
    </>
  } else if ('status' in state && state.status === 'waiting-for-joiner') {
    return <>
      <Spinner animation='border'></Spinner>
      <p>You are {state.room.participantName}. Waiting for another person to join!</p>
      <div ref={ref}></div>
    </>
  } else if ('status' in state && state.status === 'unauthenticated') {
    return <Alert variant="danger">Authentication Error. Please <a href={process.env.REACT_APP_AUTH_LOGIN_URL}>login</a> again.</Alert>
  } else {
    return <div ref={ref}></div>
  }
}

async function getRoom() {
  const jwt = document.cookie.split(';').find(c => c.startsWith('jwt='))?.split('=')?.[1];

  if (!jwt) {
    throw new UnauthenticatedError();
  }

  const headers = new Headers({ Authorization: `Bearer ${jwt}` });
  const existingRoomResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms/available`, { method: 'GET', headers });

  if (existingRoomResponse.status === 200) {
    return await existingRoomResponse.json();
  }

  if (existingRoomResponse.status === 401) {
    throw new UnauthenticatedError();
  }

  if (existingRoomResponse.status === 404) {
    const newRoomResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms`, {
      method: 'POST',
      headers
    });

    if (newRoomResponse.status === 200) {
      return newRoomResponse.json();
    } else if (newRoomResponse.status === 401) {
      throw new UnauthenticatedError();
    } else {
      throw new Error(`Unhandled status ${newRoomResponse.status}`);
    }
  }
}