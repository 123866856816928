import { Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';
import './App.css';
import { Nav } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Room } from './components/room';
import { AuthResponse } from './components/auth-response';
import { RequiresAuth } from './components/requires-auth';
import React from 'react';
import { Logout } from './components/logout';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Brand>Video Meetings</Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link href={process.env.REACT_APP_AUTH_PROFILE_URL}>Edit Profile</Nav.Link>
            <LinkContainer to="/logout">
              <Nav.Link>Logout</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link><QuestionCircle /></Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
         <Route path="/about">
           <p>About!</p>
         </Route>
         <Route path="/auth-response" component={AuthResponse} />
         <Route path="/">
           <RequiresAuth><Room /></RequiresAuth>
         </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
